import { useEffect } from "react";
import { Header } from "./components/Header";
import { Breadcrumbs } from "./components/Breadcrumbs";

export const Imprint = () => {
  useEffect(() => {
    document.title = "Cellia – Impressum";
  }, []);
  return (
    <>
      <Header />
      <Breadcrumbs
        crumbs={[
          { title: "Home", path: "/" },
          { title: "Impressum", path: "/imprint" },
        ]}
      />
      <div className="prose dark:prose-invert m-auto">
        <h1>Impressum</h1>
        <p>Inhaltlich verantwortlich</p>
      </div>
    </>
  );
};

export const Privacy = () => {
  useEffect(() => {
    document.title = "Cellia – Datenschutz";
  }, []);
  return (
    <>
      <Header />
      <Breadcrumbs
        crumbs={[
          { title: "Home", path: "/" },
          { title: "Datenschutz", path: "/privacy" },
        ]}
      />
      <div className="prose dark:prose-invert m-auto">
        <h1>Datenschutz</h1>
        <p>Diese Website erhebt und verarbeitet keine persönlichen Daten.</p>
      </div>
    </>
  );
};
