import {
    Squares2X2Icon
} from "@heroicons/react/24/solid";
import {
    NumberParam,
    StringParam,
    useQueryParam
} from "use-query-params";

/**
 * Provides controls to manipulate the query params for sorting and pagination count
 */
export const Sort = () => {
    const [perPageParam, setPerPageParam] = useQueryParam('pp', NumberParam);
    const [sortingParam, setSortingParam] = useQueryParam('sort', StringParam);

    return (
        <div className="flex flex-wrap items-center gap-6 mb-4">
            <label htmlFor="sortBy" className="inline-flex items-center gap-3">
                <span className="text-violet-700 dark:text-purple-500">Sortieren</span>
                <select name="sortBy"
                    id="sortBy"
                    className="block w-full py-2 pl-3 pr-10 mt-1 text-base text-indigo-800 border-transparent rounded-md shadow dark:text-white dark:bg-indigo-800 focus:outline-none focus:ring-violet-500 focus:border-violet-500 sm:text-sm"

                    onChange={(evt) => setSortingParam(evt.target.value)}
                    value={sortingParam || 'title'}>
                    <option value="title">Alphabetisch</option>
                    <option value="date">Chronologisch</option>
                </select>
            </label>
            <Squares2X2Icon className="inline-block w-5 h-5 text-violet-700 dark:text-purple-500" />
            {[15, 45, 90].map((n, i) =>
                <button key={i}
                    onClick={() => setPerPageParam(n)}
                    className={`${n === (perPageParam || 15) ? 'font-semibold text-violet-700 dark:text-white underline' : 'text-purple-500 dark:text-purple-500'}`}>
                    {n}
                </button>
            )}
        </div>
    );
}