import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQueryParam, StringParam } from "use-query-params";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";

/**
 * Component that manipulates search query param and navigates if necessary
 */
export const SearchBar = () => {
  const [searchQueryParam, setSearchQueryParam] = useQueryParam('s', StringParam);
  const [query, setQuery] = useState(searchQueryParam || '');
  const location = useLocation();
  const navigate = useNavigate();

  const inputHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(evt.target.value);
  }

  const submitHandler = (evt: React.FormEvent<HTMLFormElement>) => {
    // change query in place if on slices view, navigate otherwise
    if (location.pathname.includes('slices') || location.pathname.includes('course')) {
      setSearchQueryParam(query);
    } else {
      navigate('/slices?s=' + query);
    }
  }

  return (
    <form className="relative w-full rounded-full text-violet-700 dark:text-white max-w-prose" onSubmit={submitHandler}>
      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
        <MagnifyingGlassIcon className="w-5 h-5" aria-hidden="true" />
      </div>
      <input
        type="search"
        name="search"
        id="search"
        className="block w-full pl-10 border-transparent rounded-full shadow dark:text-white dark:bg-indigo-800 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm placeholder-violet-700/75 dark:placeholder-white/75"
        placeholder="Suche"
        value={query}
        onChange={inputHandler}
      />
    </form>
  );
}
