import { useState } from 'react';
import { useQuery } from "react-query";
import {
  useQueryParam,
  StringParam,
  BooleanParam,
  ArrayParam,
  UrlUpdateType
} from 'use-query-params';
import {
  OrganSystem,
  Tissue,
  Organ,
  getOrgan,
  getOrganIds,
  getOrganSystem,
  getOrganSystemIds,
  getTissue,
  getTissueIds
} from "../api";

const Filter = (props: {
  id: number,
  type: string,
  fetchHandler: (id: number) => Promise<OrganSystem | Organ | Tissue>,
  filterHandler: (checked: boolean) => void,
  initial?: boolean
}) => {
  const { isLoading, isError, data, error } =
    useQuery([props.type, props.id], () => props.fetchHandler(props.id));
  // TODO: initialize this from query param
  const [checked, setChecked] = useState(props.initial || false);

  if (isLoading) {
    return (
      <div className="flex gap-2">
        <div className="w-3 h-3 bg-indigo-800 shadow animate-pulse"></div>
        <div className="w-2/3 h-3 bg-indigo-800 shadow animate-pulse"></div>
      </div>
    );
  }

  if (isError) {
    if (error instanceof Error) {
      return <span>Fehler: {error.message}</span>
    } else {
      return <span>Unbekannter Fehler ist aufgetreten</span>
    }
  }

  const handleChange = () => {
    setChecked(!checked);
    props.filterHandler(checked);
  }

  return (
    <label className="flex items-center gap-2 hover:cursor-pointer" htmlFor={`filter-${props.type}-${props.id}`}>
      <input className="filter-input"
             id={`filter-${props.type}-${props.id}`}
             name={`filter-${props.type}-${props.id}`}
             type="checkbox"
             onChange={handleChange}
             checked={checked} />
      {data?.title}
    </label>
  );
}

export const FilterBar = (props: { filters: Array<String> }) => {
  const organIds = useQuery(['organs'], getOrganIds);
  const organSystemIds = useQuery(['organSystems'], getOrganSystemIds);
  const tissueIds = useQuery(['tissues'], getTissueIds);

  const [organSystemIdsParam, setOrganSystemIdsParam] =
    useQueryParam('organSystemIds', ArrayParam);
  const [tissueIdsParam, setTissueIdsParam] =
    useQueryParam('tissueIds', ArrayParam);
  const [organIdsParam, setOrganIdsParam] =
    useQueryParam('organIds', ArrayParam);
  const [findingParam, setFindingParam] =
    useQueryParam('finding', StringParam);
  const [colorationParam, setColorationParam] =
    useQueryParam('coloration', StringParam);
  const [bonusVideoParam, setBonusVideoParam] =
    useQueryParam('video', BooleanParam);
  const [bonusQuizParam, setBonusQuizParam] =
    useQueryParam('quiz', BooleanParam);

  const filterHandler = (
    param: (string | null)[] | null | undefined,
    id: number,
    paramHandler: (newValue: (string | null)[] | null | undefined, updateType?: UrlUpdateType) => void
  ) => {
    if (param?.includes(id.toString())) {
      paramHandler(param.filter(p => p !== id.toString()), 'replace');
    } else {
      paramHandler([...(param || []), id.toString()], 'replace');
    }
  }
  
  return (
    <>
      <div className="text-lg text-violet-700 dark:text-white">
        <div className="flex flex-col gap-2 basis-96">
          {props.filters.includes('organSystems') &&
            <div>
              <span className="block mb-2 text-3xl">Organsysteme</span>
              {organSystemIds.isLoading && <span>Organsysteme laden…</span>}
              {organSystemIds.isSuccess && organSystemIds.data.map((o, i) =>
                <div className="mb-1" key={i}>
                  <Filter id={o.id} type="organSystem"
                    fetchHandler={getOrganSystem}
                    filterHandler={() => filterHandler(organSystemIdsParam, o.id, setOrganSystemIdsParam)}
                    initial={organSystemIdsParam?.includes('' + o.id)}
                  />
                </div>
              )}
            </div>
          }
          {props.filters.includes('tissues') &&
            <div>
              <span className="block mb-2 text-3xl">Gewebe</span>
              {tissueIds.isLoading && <span>Gewebe laden…</span>}
              {tissueIds.isSuccess && tissueIds.data.map((t, i) =>
                <div className="mb-1" key={i}>
                  <Filter key={i} id={t.id} type="tissue"
                    fetchHandler={getTissue}
                    filterHandler={() => filterHandler(tissueIdsParam, t.id, setTissueIdsParam)}
                    initial={tissueIdsParam?.includes('' + t.id)}
                  />
                </div>
              )}
            </div>
          }
          {props.filters.includes('organs') &&
            <div>
              <span className="block mb-2 text-3xl">Organ</span>
              {organIds.isLoading && <span>Organe laden…</span>}
              {organIds.isSuccess && organIds.data.map((o, i) =>
                <div className="mb-1" key={i}>
                  <Filter key={i} id={o.id} type="organ"
                    fetchHandler={getOrgan}
                    filterHandler={() => filterHandler(organIdsParam, o.id, setOrganIdsParam)}
                    initial={organIdsParam?.includes('' + o.id)}
                  />
                </div>
              )}
            </div>
          }
          {props.filters.includes('findings') &&
            <div>
              <span className="block mb-2 text-3xl">Befund</span>
              {['physiologic', 'pathologic'].map((finding, i) =>
                <div className="mb-1" key={i}>
                  <label className="flex items-center gap-2 hover:cursor-pointer" htmlFor={`filter-finding-${finding}`}>
                    <input className="filter-input" id={`filter-finding-${finding}`}
                      name={`filter-finding`} type="radio" checked={findingParam === finding}
                      onChange={() => { setFindingParam(finding) }} />
                    {finding}
                  </label>
                </div>
              )}
            </div>
          }
          {props.filters.includes('coloration') &&
            <div>
              <span className="block mb-2 text-3xl">Färbung</span>
              {['Azan', 'Eisenhämatoxylin', 'Elastika-Färbung', 'van Gieson', 'Goldner', 'HE', 'Masson'].map((coloration, i) =>
                <div className="mb-1" key={i}>
                  <label className="flex items-center gap-2 hover:cursor-pointer" htmlFor={`filter-coloration-${coloration}`}>
                    <input className="filter-input"
                           id={`filter-coloration-${coloration}`}
                           name={`filter-coloration`}
                           type="radio"
                           onChange={() => setColorationParam(coloration)}
                           checked={colorationParam === coloration} />
                    {coloration}
                  </label>
                </div>
              )}
            </div>
          }
          {props.filters.includes('bonus') &&
            <div>
              <span className="block mb-2 text-3xl">Zusatzmaterial</span>
              <div className="mb-1">
                <label className="flex items-center gap-2 hover:cursor-pointer" htmlFor="filter-bonus-quiz">
                  <input className="filter-input"
                         id="filter-bonus-quiz"
                         name="filter-bonus-quiz"
                         type="checkbox"
                         onChange={(evt) => setBonusQuizParam(evt.target.checked)}
                         checked={!!bonusQuizParam} />
                  Quiz
                </label>
              </div>
              <div className="mb-1">
                <label className="flex items-center gap-2 hover:cursor-pointer" htmlFor="filter-bonus-video">
                  <input className="filter-input"
                         id="filter-bonus-video"
                         name="filter-bonus-video"
                         type="checkbox"
                         onChange={(evt) => setBonusVideoParam(evt.target.checked)}
                         checked={!!bonusVideoParam} />
                  Video
                </label>
              </div>
            </div>
          }
        </div>
      </div>
    </>
  );
}