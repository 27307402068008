import { useEffect, useState } from 'react';
import { Annotations } from '../api';
import OpenSeadragon from 'openseadragon'; // @ts-ignore;
import * as Annotorious from '@recogito/annotorious-openseadragon';
import '@recogito/annotorious-openseadragon/dist/annotorious.min.css';
import SelectorPack from '@recogito/annotorious-selector-pack';
import {
  MagnifyingGlassPlusIcon,
  MagnifyingGlassMinusIcon,
  ArrowsPointingOutIcon,
  ViewfinderCircleIcon,
  EyeIcon,
  EyeSlashIcon
} from "@heroicons/react/24/solid";

const OpenSeaDragonViewer = (props: {
  url: string,
  annotations: Annotations
}) => {
  const [hidden, hide] = useState(false);

  useEffect(() => {
    const viewer = OpenSeadragon({
      id: "openseadragon",
      showNavigator: true,
      tileSources: props.url,
      zoomInButton: 'zoomIn',
      zoomOutButton: 'zoomOut',
      homeButton: 'home',
      fullPageButton: 'fullscreen'
    });

    const config = {
      readOnly: true,
    };

    // Initialize Annotorious plugin
    const anno = Annotorious(viewer, config);
    new SelectorPack(anno);

    // Load example annotations
    anno.setAnnotations(props.annotations);

    return () => viewer.destroy();
  }, [props.annotations, props.url]);

  return (
    <div className="relative">
      <div id="openseadragon" className={`h-screen md:aspect-video lg:h-auto ${hidden ? ' hideAnno' : ''}`}>
      </div>
      <div className="absolute flex flex-col gap-2 left-2 top-2">
        <div id="fullscreen" className="p-2 rounded-lg bg-gray-400/30 backdrop-blur-sm hover:cursor-pointer">
          <ArrowsPointingOutIcon className="z-10 w-8 h-8" />
        </div>
        <div id="home" className="p-2 rounded-lg bg-gray-400/30 backdrop-blur-sm hover:cursor-pointer">
          <ViewfinderCircleIcon className="z-10 w-8 h-8" />
        </div>
      </div>
      <div className="absolute flex flex-col gap-2 left-2 bottom-2">
        <div id="zoomIn" className="p-2 rounded-lg bg-gray-400/30 backdrop-blur-sm hover:cursor-pointer">
          <MagnifyingGlassPlusIcon className="z-10 w-8 h-8" />
        </div>
        <div id="zoomOut" className="p-2 rounded-lg bg-gray-400/30 backdrop-blur-sm hover:cursor-pointer">
          <MagnifyingGlassMinusIcon className="z-10 w-8 h-8" />
        </div>
      </div>

      <div className="absolute flex gap-2 right-2 bottom-2">
        {hidden ?
          <div onClick={() => hide(false)} className="p-2 rounded-lg bg-gray-400/30 backdrop-blur-sm hover:cursor-pointer">
            <EyeSlashIcon className="z-10 w-8 h-8" />
          </div> :
          <div onClick={() => hide(true)} className="p-2 rounded-lg bg-gray-400/30 backdrop-blur-sm hover:cursor-pointer">
            <EyeIcon className="z-10 w-8 h-8" />
          </div>
        }
      </div>
    </div>
  )
};

export default OpenSeaDragonViewer;
