import { useEffect } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getCourse } from "./api";
import { Breadcrumbs } from "./components/Breadcrumbs"

/**
 * Display course metadata, to be used as a child of Slices
 */
const Course = (props: any) => {
  const id = parseInt(useParams().id as string);
  const { isLoading, isError, data, error } =
    useQuery(['course', id], () => getCourse(id));

  useEffect(() => {
    document.title = `Cellia – ${data?.title}`;
  }, [data]);

  if (isLoading) {
    return <div>Kurs lädt</div>
  }

  if (isError) {
    if (error instanceof Error) {
      return <div>Fehler: {error.message}</div>
    } else {
      return <div>Unbekannter Fehler ist aufgetreten</div>
    }
  }

  if (data?.id === undefined) {
    return <div>Kurs konnte nicht gefunden werden</div>
  }

  return (
    <>
      <Breadcrumbs crumbs={[
        { title: 'Home', path: '/' },
        { title: data?.title, path: `/course/${data?.id}` }
      ]} />
      <div className="flex items-center justify-center mb-4 text-5xl text-white rounded-lg bg-indigo-800 h-36 sm:h-48 md:h-56"
           key={data?.id}>
        {data?.title}
      </div>
      <div className="flex flex-wrap mb-4">
        {data?.description &&
          <section className="mb-4 grow basis-48">
            <header className="mb-2 text-3xl font-semibold">Einführung</header>
            {data?.description}
          </section>}
        { data?.goals &&
          <section className="grow basis-48">
            <header className="mb-2 text-3xl font-semibold">Lernziele</header>
            <ul className="pl-6 list-disc">
              {data?.goals.split(', ').map((g, i) =>
                <li className="mb-1" key={i}>{g}</li>
              )}
            </ul>
          </section>}
      </div>
    </>
  );
}

export default Course;