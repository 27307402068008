import {
  useQueryParam,
  NumberParam,
  StringParam,
  BooleanParam,
  ArrayParam,
} from "use-query-params";
import { useQuery } from "react-query";
import { getSlice, getSlices, slicesParams } from "./api";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { FilterBar } from "./components/Filter";
import { Pagination } from "./components/Pagination";
import { Sort } from "./components/Sort";
import { Header } from "./components/Header";
import { Breadcrumbs } from "./components/Breadcrumbs";

/**
 * Links to a slice by id
 */
const SliceLink = (props: { id: number }) => {
  const { isLoading, isError, data, error } = useQuery(
    ["slice", props.id],
    () => getSlice(props.id),
  );

  if (isLoading) {
    //return <span>Slice lädt</span>;
    return <></>;
  }

  if (isError) {
    if (error instanceof Error) {
      console.log(`Fehler: ${error.message}`);
    } else {
      console.log("Unbekannter Fehler");
    }
    return <></>;
  }

  return (
    <li className="structure-card aspect-[16/9] relative flex">
      <Link to={"/slice/" + data?.id} className="after:absolute after:inset-0">
        {data?.title}
      </Link>
    </li>
  );
};

/**
 * A filtered, sorted, paginated list of slices. Fetches slices according to
 * the current query params
 */
export const SliceList = () => {
  const [pageParam, setPageParam] = useQueryParam("p", NumberParam);
  const [perPageParam] = useQueryParam("pp", NumberParam);
  const [sortingParam] = useQueryParam("sort", StringParam);
  const [organSystemIdsParam] = useQueryParam("organSystemIds", ArrayParam);
  const [tissueIdsParam] = useQueryParam("tissueIds", ArrayParam);
  const [organIdsParam] = useQueryParam("organIds", ArrayParam);
  const [findingParam] = useQueryParam("finding", StringParam);
  const [colorationParam] = useQueryParam("coloration", StringParam);
  const [bonusVideoParam] = useQueryParam("video", BooleanParam);
  const [bonusQuizParam] = useQueryParam("quiz", BooleanParam);

  const [slicesParameters, setSlicesParameters] = useState<slicesParams>({
    page: pageParam || 1,
    perPage: perPageParam || 15,
    sort: sortingParam || "title",
  });

  useEffect(() => {
    let slicesParams: slicesParams = {
      page: pageParam || 1,
      perPage: perPageParam || 15,
      sort: sortingParam || "title",
    };

    if (organSystemIdsParam) {
      const organSystemIds = organSystemIdsParam.flatMap((f) =>
        f ? [parseInt(f)] : [],
      );
      slicesParams["organSystemIds"] = organSystemIds;
    }

    if (tissueIdsParam) {
      const tissueIds = tissueIdsParam.flatMap((f) => (f ? [parseInt(f)] : []));
      slicesParams["tissueIds"] = tissueIds;
    }

    if (organIdsParam) {
      const organIds = organIdsParam.flatMap((f) => (f ? [parseInt(f)] : []));
      slicesParams["organIds"] = organIds;
    }

    if (findingParam === "pathologic" || findingParam === "physiologic") {
      slicesParams["finding"] = findingParam;
    }

    if (
      colorationParam === "Azan" ||
      colorationParam === "Eisenhämatoxylin" ||
      colorationParam === "Elastika-Färbung" ||
      colorationParam === "van Gieson" ||
      colorationParam === "Goldner" ||
      colorationParam === "HE" ||
      colorationParam === "Masson"
    ) {
      slicesParams["coloration"] = colorationParam;
    }

    if (bonusVideoParam) {
      slicesParams["bonusVideo"] = true;
    }

    if (bonusQuizParam) {
      slicesParams["bonusQuiz"] = true;
    }
    setSlicesParameters(slicesParams);
  }, [
    pageParam,
    perPageParam,
    sortingParam,
    organSystemIdsParam,
    tissueIdsParam,
    organIdsParam,
    findingParam,
    colorationParam,
    bonusVideoParam,
    bonusQuizParam,
  ]);

  const { isLoading, isError, data, error } = useQuery(
    ["slices", slicesParameters],
    () => getSlices(slicesParameters),
  );

  if (isLoading) {
    return <span>Slices laden</span>;
  }

  if (isError) {
    if (error instanceof Error) {
      return <span>Fehler: {error.message}</span>;
    } else {
      return <span>Unbekannter Fehler ist aufgetreten</span>;
    }
  }

  return (
    <div>
      <ul className="w-full mb-4 grid gap-2 grid-cols-[repeat(auto-fit,minmax(20ch,1fr))] grid-rows-auto auto-rows-max">
        {data?.map((s, i) => <SliceLink key={i} id={s.id} />)}
      </ul>
      <Pagination
        page={pageParam || 1}
        pageHandler={setPageParam}
        pages={100}
      />
    </div>
  );
};

type SlicesProps = {
  children?: React.ReactNode;
};

/**
 * View that lists slices and provides filtering, sorting, pagination.
 * Optionally takes in children and displays them above the slice list.
 * Children bring their own breadcrumbs, hansel & gretel style.
 */
const Slices: React.FC<SlicesProps> = ({ children }) => {
  useEffect(() => {
    if (!children) {
      document.title = `Cellia – Schnitte`;
    }
  }, [children]);

  return (
    <>
      <Header />
      <div className="flex flex-wrap gap-16">
        <div className="flex flex-col order-2 gap-2 md:order-1">
          <FilterBar
            filters={[
              "organSystems",
              "tissues",
              "organs",
              "findings",
              "coloration",
              "bonus",
            ]}
          />
        </div>
        <div className="order-1 md:order-2 grow">
          {children ? (
            <>
              {children}
              <div className="flex justify-end">
                <Sort />
              </div>
            </>
          ) : (
            <div className="flex flex-wrap justify-between">
              <Breadcrumbs
                crumbs={[
                  { title: "Home", path: "/" },
                  { title: "Schnitte", path: "/slices/" },
                ]}
              />
              <Sort />
            </div>
          )}
          <SliceList />
        </div>
      </div>
    </>
  );
};

export default Slices;
