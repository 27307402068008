import { Link } from "react-router-dom";
// @ts-ignore
import { ReactComponent as Logo } from '../logo-big.svg';
import { SearchBar } from './SearchBar';
import ColorSchemeToggle from "./ColorSchemeToggle";

export const Header = () => {
    return (
        <header className="flex flex-wrap items-center gap-2 mb-4">
            <Link className="mb-2 mr-auto logo-small" to="/">
                <Logo className="h-10" />
            </Link>
            <ColorSchemeToggle />
            <SearchBar />
        </header>
    );
}