import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";

export type crumb = {
    title: string,
    path: string
}

export const Breadcrumbs = (props: {
    crumbs: crumb[]
}) => {
    return (
        <div className="py-2 mb-2 bg-transparent rounded-lg text-violet-700 dark:text-white">
            <ol className="flex gap-2">
                {props.crumbs.map((c, i) =>
                    <li className="flex items-center gap-2 last:font-semibold last:underline" key={i}>
                        { i !== 0 && <ChevronRightIcon className="w-5 h-5" />}
                        <Link to={c.path}>
                            {c.title}
                        </Link>
                    </li>
                )}
            </ol>
        </div>
    );
}