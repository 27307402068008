import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import ColorSchemeToggle from "./components/ColorSchemeToggle";

import {
  getCourse,
  getCourseIds,
  getOrganSystem,
  getOrganSystemIds,
  getTissue,
  getTissueIds,
} from "./api";

// @ts-ignore
import { ReactComponent as Logo } from "./logo-big.svg";
import { SearchBar } from "./components/SearchBar";

// Structures have a title and children to structure
type StructureProps = {
  children: React.ReactNode;
  title: String;
};

/**
 * Structure provides the grid in which things are laid out on the Homepage
 */
const Structure: React.FC<StructureProps> = ({ title, children }) => {
  return (
    <div className="mb-8">
      <h1 className="mb-4 text-4xl font-medium text-indigo-700 dark:text-white">
        {title}
      </h1>
      <ul className="grid auto-rows-fr gap-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6">
        {children}
      </ul>
    </div>
  );
};

const Course = (props: { id: number }) => {
  const { isLoading, isError, data, error } = useQuery(
    ["courses", props.id],
    () => getCourse(props.id),
  );

  if (isLoading) {
    return <span>Kurs lädt</span>;
  }

  if (isError) {
    if (error instanceof Error) {
      console.log(error.message);
      return <span>{error.message}</span>;
    } else {
      return <span>Unbekannter Fehler</span>;
    }
  }

  return (
    <Link to={`/course/${data?.id}`} className="after:absolute after:inset-0">
      {data?.title}
    </Link>
  );
};

const Courses = () => {
  const { isLoading, isError, data, error } = useQuery(
    ["courses"],
    getCourseIds,
  );
  if (isLoading) {
    return <span>Kurse laden</span>;
  }

  if (isError) {
    if (error instanceof Error) {
      return <span>Fehler: {error.message}</span>;
    } else {
      return <span>Unbekannter Fehler ist aufgetreten</span>;
    }
  }

  return (
    <Structure title="Kurse">
      {data?.map((c, i) => (
        <li className="structure-card" key={i}>
          <Course id={c.id} />
        </li>
      ))}
    </Structure>
  );
};

const OrganSystem = (props: { id: number }) => {
  const { isLoading, isError, data, error } = useQuery(
    ["organSystem", props.id],
    () => getOrganSystem(props.id),
  );

  if (isLoading) {
    return <span>Organsystem lädt</span>;
  }

  if (isError) {
    if (error instanceof Error) {
      console.log(error.message);
      return <span>{error.message}</span>;
    } else {
      return <span>Unbekannter Fehler</span>;
    }
  }

  return (
    <Link
      to={`/slices/?organSystemIds=${data?.id}`}
      className="after:absolute after:inset-0"
    >
      {data?.title}
    </Link>
  );
};

const OrganSystems = () => {
  const { isLoading, isError, data, error } = useQuery(
    ["organSystems"],
    getOrganSystemIds,
  );
  if (isLoading) {
    return <span>Organsysteme laden</span>;
  }

  if (isError) {
    if (error instanceof Error) {
      return <span>Fehler: {error.message}</span>;
    } else {
      return <span>Unbekannter Fehler ist aufgetreten</span>;
    }
  }

  return (
    <Structure title="Organsysteme">
      {data?.map((o, i) => (
        <li className="structure-card" key={i}>
          <OrganSystem id={o.id} />
        </li>
      ))}
    </Structure>
  );
};

const Tissue = (props: { id: number }) => {
  const { isLoading, isError, data, error } = useQuery(
    ["tissue", props.id],
    () => getTissue(props.id),
  );
  if (isLoading) {
    return <span>Gewebe lädt</span>;
  }

  if (isError) {
    if (error instanceof Error) {
      console.log(error.message);
      return <span>{error.message}</span>;
    } else {
      return <span>Unbekannter Fehler</span>;
    }
  }

  return (
    <Link
      to={`/slices/?tissueIds=${data?.id}`}
      className="after:absolute after:inset-0"
    >
      {data?.title}
    </Link>
  );
};

const Tissues = () => {
  const { isLoading, isError, data, error } = useQuery(
    ["tissues"],
    getTissueIds,
  );
  if (isLoading) {
    return <span>Gewebe laden</span>;
  }

  if (isError) {
    if (error instanceof Error) {
      return <span>Fehler: {error.message}</span>;
    } else {
      return <span>Unbekannter Fehler ist aufgetreten</span>;
    }
  }

  return (
    <Structure title="Gewebe">
      {data?.map((t, i) => (
        <li className="structure-card" key={i}>
          <Tissue id={t.id} />
        </li>
      ))}
    </Structure>
  );
};

/**
 * Home view, shows a big chunky header and a grid of links for a couple of
 * structures
 */
const Home = () => {
  return (
    <>
      <div className="mb-4 text-right md:absolute md:right-5 md:top-5 md:mb-0">
        <ColorSchemeToggle />
      </div>
      <header className="mb-10 flex flex-col items-center gap-2">
        <Logo className="logo-home mb-4 max-w-prose" />
        <SearchBar />
      </header>
      <OrganSystems />
      <Tissues />
      <Courses />
    </>
  );
};

export default Home;
