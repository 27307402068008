import {
  useMemo
} from 'react';
import {
  QueryClient,
  QueryClientProvider
} from "react-query";
import { QueryParamProvider } from 'use-query-params';
import {
  HashRouter,
  Route,
  Routes,
  useNavigate,
  useLocation,
  Link
} from "react-router-dom";
import './App.css';

import Course from './Course';
import Home from './Home';
import Slice from './Slice';
import Slices from './Slices';
import { Imprint, Privacy } from './Pages';

const queryClient = new QueryClient();

/**
 * Root node that wraps Cellias routes with a styled container and
 * providers for our API requests and query param handling
 */
const App = () => {
  useMemo(() => {
    if (!('theme' in localStorage) || !(['system', 'dark', 'light'].includes(localStorage.theme))) {
      localStorage.theme = 'system';
    }
    switch(localStorage.theme) {
      case 'system':
        if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
          document.documentElement.classList.add('dark');
        } else {
          document.documentElement.classList.remove('dark');
        }
        break;
      case 'dark':
        document.documentElement.classList.add('dark');
        break;
      case 'light':
        document.documentElement.classList.remove('dark');
        break;
    }
  }, []);

  return (
    <HashRouter>
      <QueryParamProvider ReactRouterRoute={RouteAdapter}>
        <QueryClientProvider client={queryClient}>
          <div className="flex flex-col min-h-screen bg-indigo-100 dark:text-white dark:bg-indigo-900">
            <div className="p-8 grow">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/slices" element={<Slices />} />
                <Route path="/slice/:id" element={<Slice />} />
                <Route path="/course/:id" element={<Slices><Course /></Slices>} />
                <Route path="/imprint" element={<Imprint />} />
                <Route path="/privacy" element={<Privacy />} />
              </Routes>
            </div>
            <div className="flex justify-between p-8 bg-white dark:bg-gray-900">
              <div className='flex gap-1'>
                Cellia &ndash;
                <a href="https://avmz-medizin.rwth-aachen.de">
                  AVMZ
                </a>
                2022
              </div>
              <ul className="flex gap-2">
                <li><Link to="/privacy">Datenschutz</Link></li>
                <li><Link to="/imprint">Impressum</Link></li>
              </ul>
            </div>
          </div>
        </QueryClientProvider>
      </QueryParamProvider>
    </HashRouter>
  );
}

type Props = {
  children?: React.ReactNode
};

// needed for Typescript not to complain about the query param provider
// something something react router
// https://github.com/pbeshai/use-query-params/issues/196
const RouteAdapter: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const adaptedHistory = useMemo(
    () => ({
      // can disable eslint for parts here, location.state can be anything
      replace(location: Location) {
        // @ts-ignore
        navigate(location, { replace: true, state: location.state });
      },
      push(location: Location) {
        navigate(location, {
          replace: false,
          // @ts-ignore
          state: location.state,
        });
      },
    }),
    [navigate]
  );
  // @ts-ignore
  return children({ history: adaptedHistory, location });
};

export default App;