import {
    ChevronLeftIcon,
    ChevronRightIcon
} from "@heroicons/react/24/solid";

export const Pagination = (props: {
    page: number,
    pageHandler: (arg0: number) => void,
    pages: number
}) => {
    return (
        <div className="flex items-center justify-end gap-2">
            <span className="text-violet-700 dark:text-purple-500">Seite</span>
            <select name="page"
                    className="py-2 pl-3 pr-10 mt-1 text-base border-transparent rounded-md shadow dark:bg-indigo-800 focus:outline-none focus:ring-violet-500 focus:border-violet-500 sm:text-sm"
                    onChange={(evt) => props.pageHandler(parseInt(evt.target.value))}
                    value={props.page}>
                {[...Array(props.pages).keys()].map((p, i) => 
                    <option key={i} value={ p + 1 }>
                        { p + 1 }
                    </option>
                )}
            </select>
            <button onClick={() => props.pageHandler(props.page - 1)}
                    className={ props.page === 1 ? "text-slate-400 dark:text-white/50" : "text-violet-700 dark:text-purple-500"}
                    disabled={ props.page === 1 }>
                <ChevronLeftIcon className="w-5 h-5" />
            </button>
            <button onClick={() => props.pageHandler(props.page + 1)}
                    className={ props.page === props.pages ? "text-white/75" : "text-purple-500"}
                    disabled={ props.page === props.pages }>
                <ChevronRightIcon className="w-5 h-5" />
            </button>
        </div>
    );
}