import { Header } from "./components/Header";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import Viewer from './components/OpenSeaDragon';
import {
  getCourse,
  getOrgan,
  getOrganSystem,
  getSlice,
  getTag
} from "./api";
import {
  FaPlay,
  FaQuestion
} from "react-icons/fa";

const CourseLink = (props: { id: number }) => {
  const { isLoading, isError, data, error } =
    useQuery(['course', props.id], () => getCourse(props.id));
  if (isLoading) {
    return <span>Kurs lädt</span>
  }

  if (isError) {
    if (error instanceof Error) {
      return <span>Fehler: {error.message}</span>
    } else {
      return <span>Unbekannter Fehler ist aufgetreten</span>
    }
  }

  return (
    <Link to={`/course/${props.id}`}>{data?.title}</Link>
  );
}

const OrganSystemLink = (props: { id: number }) => {
  const { isLoading, isError, data, error } =
    useQuery(['organSystem', props.id], () => getOrganSystem(props.id));

  if (isLoading) {
    return <span>Organsystem lädt</span>
  }

  if (isError) {
    if (error instanceof Error) {
      return <span>Fehler: {error.message}</span>
    } else {
      return <span>Unbekannter Fehler ist aufgetreten</span>
    }
  }

  return (
    <Link to={`/slices/?organSystemId=${props.id}`}>{data?.title}</Link>
  );
}

const OrganLink = (props: { id: number }) => {
  const { isLoading, isError, data, error } =
    useQuery(['organ', props.id], () => getOrgan(props.id));

  if (isLoading) {
    return <span>Organ lädt</span>
  }

  if (isError) {
    if (error instanceof Error) {
      return <span>Fehler: {error.message}</span>
    } else {
      return <span>Unbekannter Fehler ist aufgetreten</span>
    }
  }

  return (
    <Link to={`/slices/?organId=${props.id}`}>{data?.title}</Link>
  );
}

const FindingLink = (props: { finding: string }) => {
  return (
    <Link to={`/slices/?finding=${props.finding}`}>{props.finding}</Link>
  );
}

const Tag = (props: { id: number }) => {
  const { isLoading, isError, data, error } =
    useQuery(['tag', props.id], () => getTag(props.id));

  if (isLoading) {
    return <span>Tag lädt</span>
  }

  if (isError) {
    if (error instanceof Error) {
      return <span>Fehler: {error.message}</span>
    } else {
      return <span>Unbekannter Fehler ist aufgetreten</span>
    }
  }

  return (
    <Link to={`/slices/?tagId=${props.id}`}
      className="px-3 py-1 bg-white rounded-full dark:bg-indigo-800">
      {data?.title}
    </Link>
  );
}

const Slice = () => {
  const id = parseInt(useParams().id as string);
  const slice = useQuery(['slice', id], () => getSlice(id));
  if (slice.isLoading) {
    return <span>Slice lädt</span>
  }

  if (slice.isError) {
    if (slice.error instanceof Error) {
      return <span>Fehler: {slice.error.message}</span>
    } else {
      return <span>Unbekannter Fehler ist aufgetreten</span>
    }
  }

  return (
    <>
      <Header />
      {slice.data?.filename &&
        <div className="self-center m-auto mb-6 bg-white rounded-lg shadow max-w-7xl md:p-2 dark:bg-indigo-800">
          <Viewer url={slice.data?.filename} annotations={slice.data?.annotations} />
        </div>}
      <article className="flex flex-wrap justify-between m-auto divide-x-2 rounded-lg shadow max-w-7xl divide-violet-700 dark:divide-purple-600 md:p-6 md:bg-white md:dark:bg-indigo-800">
        <div className="flex flex-col pr-4 prose grow dark:prose-invert">
          <header className="mb-4 text-4xl font-medium">
            {slice.data?.title || 'Unbenannter Schnitt'}
          </header>
          <div>
            <dl className="inline-grid self-start grid-cols-meta gap-x-4 gap-y-2">
              {slice.data?.courseIds.length !== 0 ?
                <>
                  <dt className="font-semibold text-violet-700 dark:text-purple-500">Kurs</dt>
                  <dd className="flex flex-wrap gap-1">{slice.data?.courseIds.map((c, i) => <CourseLink key={i} id={c} />)}</dd>
                </> : null}
              {slice.data?.location ?
                <>
                  <dt className="font-semibold text-violet-700 dark:text-purple-500">Histokasten</dt>
                  <dd>{slice.data?.location}</dd>
                </> : null}
            </dl>
          </div>
          <p>{slice.data?.description || 'Keine Beschreibung vorhanden'}</p>
          <footer>
            <header className="mb-4 text-4xl font-medium">Zusatzmaterial</header>
            <div className="flex flex-wrap gap-y-2">
              {slice.data?.bonusVideo ?
                <>
                  <div className="flex items-center">
                    <div className="z-20 flex items-center justify-center w-10 h-10 text-purple-500 bg-white rounded-full shadow">
                      <FaPlay />
                    </div>
                    <div className="z-10 flex items-center pl-6 pr-4 -translate-x-4 rounded-full shadow text-violet-700 bg-slate-200 dark:text-white dark:bg-indigo-800">Video</div>
                  </div>
                </> : null}
              {slice.data?.bonusQuiz ?
                <>
                  <div className="flex items-center">
                    <div className="z-20 flex items-center justify-center w-10 h-10 text-purple-500 bg-white rounded-full shadow">
                      <FaQuestion />
                    </div>
                    <div className="z-10 flex items-center pl-6 pr-4 -translate-x-4 rounded-full shadow text-violet-700 bg-slate-200 dark:text-white dark:bg-indigo-800">Quiz</div>
                  </div>
                </> : null}
              {slice.data?.bonusVeira ?
                <>

                  <div className="flex items-center">
                    <div className="z-20 flex items-center justify-center w-10 h-10 font-bold text-purple-500 bg-white rounded-full shadow">V</div>
                    <div className="z-10 flex items-center pl-6 pr-4 -translate-x-4 rounded-full shadow text-violet-700 bg-slate-200 dark:text-white dark:bg-indigo-800">Veira</div>
                  </div>
                </> : null}
              {!slice.data?.bonusVideo && !slice.data?.bonusQuiz && !slice.data?.bonusVeira ?
                <>Kein Zusatzmaterial vorhanden</> : null}
            </div>
          </footer>
        </div>
        <aside className="flex flex-col gap-4 pl-4 shrink">
          <section>
            <header className="mb-4 text-4xl font-medium">Schnitteigenschaften</header>
            <dl className="grid grid-cols-meta gap-x-4 gap-y-2">
              {slice.data?.organSystemId ?
                <>
                  <dt className="text-violet-700 dark:text-purple-500">Organsystem</dt>
                  <dd><OrganSystemLink id={slice.data?.organSystemId} /></dd>
                </> : null}
              {slice.data?.organId ?
                <>
                  <dt className="text-violet-700 dark:text-purple-500">Organ</dt>
                  <dd><OrganLink id={slice.data?.organId} /></dd>
                </> : null}
              {slice.data?.finding ?
                <>
                  <dt className="text-violet-700 dark:text-purple-500">Befund</dt>
                  <dd><FindingLink finding={slice.data?.finding} /></dd>
                </> : null}
              {slice.data?.coloration ?
                <>
                  <dt className="text-violet-700 dark:text-purple-500">Herkunft</dt>
                  <dd>{slice.data?.origin}</dd>
                </> : null}
              {slice.data?.coloration ?
                <>
                  <dt className="text-violet-700 dark:text-purple-500">Färbung</dt>
                  <dd>{slice.data?.coloration}</dd>
                </> : null}
              {slice.data?.date ?
                <>
                  <dt className="text-violet-700 dark:text-purple-500">Datum</dt>
                  <dd><time>{slice.data?.date.toLocaleDateString()}</time></dd>
                </> : null}
            </dl>
          </section>
          <section className="flex flex-col gap-4">
            <header className="text-4xl font-medium">Tags</header>
            <div className="flex flex-wrap gap-2">
              {slice.data?.tagIds.map((t, i) =>
                <Tag key={i} id={t} />)}
              {slice.data?.tagIds.length === 0 && <>Keine Tags vorhanden</>}
            </div>
          </section>
        </aside>
      </article>
    </>
  );
}

export default Slice;